<template>
  <div class="vx-row">
    <div class="vx-card p-6">
      <h5 style="margin-bottom:9px">Recargas Manuales Realizadas</h5>
      <!-- TABLE ACTION ROW -->
      <div class="flex flex-wrap justify-between items-center">
        <!-- ITEMS PER PAGE -->
        <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{
                  currentPage * paginationPageSize - (paginationPageSize - 1)
                }}
                -
                {{
                  recharges.length - currentPage * paginationPageSize > 0
                    ? currentPage * paginationPageSize
                    : recharges.length
                }}
                de {{ recharges.length }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                <span>50</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                <span>100</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                <span>150</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <div
          class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
        >
            <p style="margin-top:-60px; margin-right:-40px; font-size:10px"> Desde</p>
            <date-picker :language="languages.es" v-model="from" :first-day-of-week="1" label="Desde" placeholder="Desde" style="margin-right:-10px"></date-picker>
            <p style="margin-top: -60px; margin-right:-40px; font-size:10px"> Hasta</p>
            <date-picker :language="languages.es" v-model="to" type="datetime" label="Hasta" placeholder="Hasta" style="margin-right:-10px"></date-picker>
          <vs-input
            class="mb-4 md:mb-0 mr-4"
            v-model="searchQuery"
            @input="updateSearchQuery"
            placeholder="Buscar..."
          />
          <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()"
            >Exportar</vs-button
          >
        </div>
      </div>
      <ag-grid-vue
        ref="agGridTable"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="recharges"
        @first-data-rendered="onFirstDataRendered"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
        :modules="modules"
      >
      </ag-grid-vue>
      <ag-grid-vue 
        style="height: 100px; flex: none;"
        class="ag-theme-material w-100 ag-grid-table total"
        :gridOptions="bottomGridOptions"
        :headerHeight="0"
        :columnDefs="columnDefs"
        :rowData="rechargesFooter"
        :modules="modules">                    
      </ag-grid-vue>
      <vs-pagination
        :total="totalPages"
        :max="maxPageNumbers"
        v-model="currentPage"
      />
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { AgGridVue } from "@ag-grid-community/vue";
import {AllCommunityModules} from '@ag-grid-community/all-modules';
import reportModule from "@/store/report/moduleReport.js";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import DatePicker from "vuejs-datepicker";
import * as lang from 'vuejs-datepicker/src/locale';

export default {
  data() {
    return {
      modules: AllCommunityModules,
      languages: lang,
      from: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
      to: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),

      //Table
      searchQuery: "",
      gridOptions: {
        alignedGrids: [],
        suppressHorizontalScroll: true
      },

      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      groupHeaderHeight: 150,
      headerHeight: 100,
      columnDefs: [
        {
          headerName: "ID",
          field: "id_sale",
          width: 10
        },
        {
          headerName: "Nombre",
          field: "name",
          cellClass:"cellMayusc"
        },
            {
          headerName: "Apellido",
          field: "last_name",
          cellClass:"cellMayusc"
        },
        {
          headerName: "Email",
          field: "email",
        },
        {
          headerName: "Fecha Recarga",
          field: "sale_date",
          comparator: this.myDateComparator,
        },
        {
          headerName: "Monto",
          field: "sale_total",
          valueFormatter: params => this.$options.filters.currencySign(params.data.sale_total, '$ '),
        },
        {
          headerName: "Tipo de Recarga",
          field: "recharge_type",
        },
        {
          headerName: "Referencia",
          field: "reference",
        },
      ],
      // Bottom Row
      bottomGridOptions: {
        alignedGrids: [],
        defaultColDef: {
            editable: false,
            sortable: true,
            resizable: true,
            filter: true,
            flex: 1,
            minWidth: 100
        }
      },

      recharges: [],
      rechargesFooter: []
    };
  },
  components: {
    VuePerfectScrollbar,
    DatePicker,
    AgGridVue,
  },

  created() {
    //Modules
    if (!reportModule.isRegistered) {
      this.$store.registerModule("reportModule", reportModule);
      reportModule.isRegistered = true;
    }

    this.loadTable();
  },

  methods: {
    isDate(val) {
        return val instanceof Date && !isNaN(val.valueOf())
    },
    getPetitionStatusColor(status) {
      let color = ''
      switch(status) {
        case 2:
        case 4:
          color = "red";
          break;
        case 3:
          color = "green";
          break;
        default:
          color = "primary"        
      }
      return {color: color, fontWeight: '600'}
    },
    loadTable() {
        var dates = {from: this.isDate(this.from)?this.from.toISOString().split('T')[0] : '', to:this.isDate(this.to)?this.to.toISOString().split('T')[0] : ''};
        this.$store.dispatch("reportModule/getGuardianRecharges", {dates: dates})
            .then(() => {   
                let recharges = this.$store.getters['reportModule/getGuardianManualRecharges']
                if (recharges.length > 0) {
                    this.recharges = recharges;
                    this.calculateFooterData();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    myDateComparator(date1, date2) {
      var date1Number = this.monthToComparableNumber(date1);
      var date2Number = this.monthToComparableNumber(date2);
      if (date1Number === null && date2Number === null) {
        return 0;
      }
      if (date1Number === null) {
        return -1;
      }
      if (date2Number === null) {
        return 1;
      }
      return date1Number - date2Number;
    },
    monthToComparableNumber(date) {
      if (date === undefined || date === null || date.length !== 10) {
        return null;
      }
      var yearNumber = date.substring(6, 10);
      var monthNumber = date.substring(3, 5);
      var dayNumber = date.substring(0, 2);
      var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
      return result;
    },
    onFirstDataRendered: function() {
      // this.gridColumnApi.autoSizeAllColumns();
    },
    calculateFooterData() {
      let footer = [{
        id_sale: 'Total',
        sale_total: this.recharges.reduce((a,b) => a + b.sale_total,0),
      }]
      this.rechargesFooter = footer
    }
  },

  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 7;
      }
    },
    from: function () {
        this.loadTable();
    },
    to: function () {
        this.loadTable();
    },
  },

  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 50;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) {
          return this.gridApi.paginationGetCurrentPage() + 1;
        }
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },

  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;

    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        ".ag-header-container"
      );
      header.style.left =
        "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
    }
  },
  beforeMount() {
    this.gridOptions.alignedGrids.push(this.bottomGridOptions);
    this.bottomGridOptions.alignedGrids.push(this.gridOptions);
  }
};
</script>

<style>
.ag-header-cell-label .ag-header-cell-text{
  overflow: visible;
  text-overflow: clip;
  white-space: normal;
}
.cellMayusc{
  text-transform: capitalize;
}
.ag-tooltip{
  color: white !important;
  background-color: #4a3cdb !important;
  padding: 1em !important;
  border-radius: 15px !important; 
}

    .ag-grid-table.total .ag-row {
        /* background-color: #7c71f138; */
        font-weight: 700;
    }
    .ag-grid-table.total .ag-header {
        border: none;
    }
</style>